import {
  Stack,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import {
  MarketingChannel,
  MarketingChannelOptions,
} from "../../../../services/core-api-adapter";
import { styleInputDefault } from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import { useId } from "react";

interface MemberOnboardingMarketingChannelInputProps {
  marketingChannels: MarketingChannel[];
  marketingChannelValue: MarketingChannel | null;
  otherSourceValue: string;
  onSelectChange: (value: MarketingChannel) => void;
  onOtherSourceValueChange: (value: string) => void;
}

export default function MemberOnboardingMarketingChannelInput({
  marketingChannels,
  marketingChannelValue,
  otherSourceValue,
  onSelectChange,
  onOtherSourceValueChange,
}: MemberOnboardingMarketingChannelInputProps) {
  const whereDidYouHearOfUnuID = useId();
  const otherInputID = useId();

  const onHandleWhereDidYouHearOfUnuInput = (
    event: SelectChangeEvent<MarketingChannel>
  ) => {
    const {
      target: { value },
    } = event;
    const marketingChannel = value as MarketingChannel;
    onSelectChange(marketingChannel);
  };

  function onOtherInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    onOtherSourceValueChange(value);
  }

  return (
    <>
      <Stack spacing={1}>
        <InputLabel htmlFor={whereDidYouHearOfUnuID}>
          <Typography color="neutral.700" variant="body2" fontWeight={300}>
            {t("WhereDidYouHearOfUnuInput.label")}
          </Typography>

          <Select
            fullWidth
            labelId={whereDidYouHearOfUnuID}
            displayEmpty
            value={marketingChannelValue || ""}
            onChange={onHandleWhereDidYouHearOfUnuInput}
            renderValue={(selected) => {
              if (!selected) {
                return <>{t("WhereDidYouHearOfUnuInput.placeholderLabel")}</>;
              }

              return t(
                `WhereDidYouHearOfUnuInput.${selected.name}.label` as any
              );
            }}
            inputProps={{
              "aria-label": t("WhereDidYouHearOfUnuInput.label"),
            }}
            MenuProps={{
              "aria-label": t("WhereDidYouHearOfUnuInput.label"),
            }}
          >
            <MenuItem disabled aria-label="Placeholder" value="">
              <>{t("WhereDidYouHearOfUnuInput.placeholderLabel")}</>
            </MenuItem>

            {marketingChannels.map(
              (marketingChannel: MarketingChannel, index) => (
                <MenuItem
                  key={marketingChannel.name + index}
                  value={marketingChannel as any}
                >
                  <ListItemText
                    primary={t(
                      `WhereDidYouHearOfUnuInput.${marketingChannel.name}.label` as any
                    )}
                  />
                </MenuItem>
              )
            )}
          </Select>
        </InputLabel>
      </Stack>

      {marketingChannelValue?.name === MarketingChannelOptions.OTHER && (
        <Stack spacing={1}>
          <InputLabel htmlFor={otherInputID}>
            <Typography color="neutral.700" variant="body2" fontWeight={300}>
              {t("OtherSelectionInput.label")}
            </Typography>
          </InputLabel>
          <TextField
            id={otherInputID}
            value={otherSourceValue}
            onInput={onOtherInput}
            inputProps={{
              autoComplete: "off",
              "aria-label": t("OtherSelectionInput.label"),
            }}
            sx={{
              input: {
                ...styleInputDefault,
                fontSize: convertPxToRem(16),
              },
            }}
            fullWidth
          />
        </Stack>
      )}
    </>
  );
}
