import { useTranslation } from "react-i18next";
import DefaultDialog from "../../../DefaultDialog";
import { Button, Stack, Typography } from "@mui/material";

export default function RetailSexAtBirthInfoModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("WhyAreWeAskingYouThisModal.title")}
      actionButtons={
        <Button size="small" onClick={() => onClose()}>
          {t("common.close")}
        </Button>
      }
    >
      <Stack textAlign="center" spacing={1}>
        {(
          t<any, any, string[]>(
            "CollectRetailUserDetails.sexAtBirth.modalContent",
            {
              returnObjects: true,
            }
          ) as []
        ).map((text: string, index: number) => (
          <Typography key={String(text + index)}>{text}</Typography>
        ))}
      </Stack>
    </DefaultDialog>
  );
}
