import { Stack, InputLabel, Typography, TextField } from "@mui/material";
import { t } from "i18next";
import { styleInputDefault } from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import { useId } from "react";

interface MemberOnboardingFullNameInputProps {
  firstNameValue: string;
  lastNameValue: string;
  onFirstNameChange: (value: string) => void;
  onLastNameChange: (value: string) => void;
}

export default function MemberOnboardingFullNameInput({
  firstNameValue,
  lastNameValue,
  onFirstNameChange,
  onLastNameChange,
}: MemberOnboardingFullNameInputProps) {
  const firstNameInputID = useId();
  const lastNameInputID = useId();

  function onFirstNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    onFirstNameChange(value);
  }

  function onLastNameInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    onLastNameChange(value);
  }

  return (
    <>
      <Stack spacing={1}>
        <InputLabel htmlFor={firstNameInputID}>
          <Typography color="neutral.700" variant="body2" fontWeight={300}>
            {t("FirstNameInput.label")}
          </Typography>
        </InputLabel>
        <TextField
          id={firstNameInputID}
          value={firstNameValue}
          onInput={onFirstNameInput}
          inputProps={{
            autoComplete: "off",
            "aria-label": t("FirstNameInput.label"),
          }}
          sx={{
            input: {
              ...styleInputDefault,
              fontSize: convertPxToRem(16),
            },
          }}
          fullWidth
        />
      </Stack>

      <Stack spacing={1}>
        <InputLabel htmlFor={lastNameInputID}>
          <Typography color="neutral.700" variant="body2" fontWeight={300}>
            {t("LastNameInput.label")}
          </Typography>
        </InputLabel>
        <TextField
          id={lastNameInputID}
          value={lastNameValue}
          onInput={onLastNameInput}
          inputProps={{
            autoComplete: "off",
            "aria-label": t("LastNameInput.label"),
          }}
          sx={{
            input: {
              ...styleInputDefault,
              fontSize: convertPxToRem(16),
            },
          }}
          fullWidth
        />
      </Stack>
    </>
  );
}
