import { useCallback, useEffect, useState } from "react";
import {
  IdentityDocumentTypeValue,
  MarketingChannel,
  MarketingChannelOptions,
  MarketingChannelStatus,
  SubmitUserDetailsRequest,
  getMember,
  submitRetailUserDetails,
} from "../../services/core-api-adapter";
import { useGlobalStore } from "../../store";
import { InternationalPhoneNumber } from "../InternationalPhoneNumberInput";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import { isValid } from "../../utils/validators/common";
import FormInputErrorList from "../FormInputErrorList";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";
import useGetMarketingChannels from "../../hooks/useGetMarketingChannels";
import MemberOnboardingDateOfBirthInput from "./components/MemberOnboardingDateOfBirthInput";
import MemberOnboardingPhoneNumberInput from "./components/MemberOnboardingPhoneNumberInput";
import MemberOnboardingFullNameInput from "./components/MemberOnboardingFullNameInput";
import MemberOnboardingMarketingChannelInput from "./components/MemberOnboardingMarketingChannelInput";
import MemberOnboardingSexAtBirthInput from "./components/MemberOnboardingSexAtBirthInput";
import {
  trackFormEvent,
  AnalyticsEvent,
} from "../../services/analytics-adapter";

interface CollectMemberOnboardingDetailsProps {
  onCompletedFlow?: Function;
  data?: {
    userDetails: {
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      sexAtBirth: string;
    };
  };
}

interface UserOnboardingDetails {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  sexAtBirth: string;
  otherSourceValue: string;
  marketingChannel: MarketingChannel | undefined;
  phoneNumber: InternationalPhoneNumber | null;
}

export default function CollectMemberOnboardingDetails({
  onCompletedFlow,
  data,
}: CollectMemberOnboardingDetailsProps) {
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();

  const [userOnboardingDetails, setUserOnboardingDetails] =
    useState<UserOnboardingDetails>({
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      sexAtBirth: "",
      otherSourceValue: "",
      marketingChannel: undefined,
      phoneNumber: null,
    });

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [shouldCollectAdditionalDetails, setShouldCollectAdditionalDetails] =
    useState<boolean>(false);

  const isLeadSourceEnabled = isFeatureEnabled(
    FeatureToggleIdentifier.ENABLE_COLLECT_LEAD_SOURCE
  );

  const {
    marketingChannels,
    marketingChannelsFetchError,
    isMarketingChannelsLoading,
  } = useGetMarketingChannels({
    status: MarketingChannelStatus.ACTIVE,
    shouldGetMarketingChannels: isLeadSourceEnabled,
  });

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Member onboarding details",
    });
  }, []);

  useEffect(() => {
    const existingUserData: UserOnboardingDetails = {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      sexAtBirth: "",
      otherSourceValue: "",
      marketingChannel: undefined,
      phoneNumber: null,
    };

    if (data?.userDetails?.firstName) {
      existingUserData.firstName = data.userDetails.firstName;
    }

    if (data?.userDetails?.lastName) {
      existingUserData.lastName = data.userDetails.lastName;
    }

    if (data?.userDetails?.dateOfBirth) {
      existingUserData.dateOfBirth = data.userDetails.dateOfBirth;
    }

    if (data?.userDetails?.sexAtBirth) {
      existingUserData.sexAtBirth = data.userDetails.sexAtBirth;
    }

    getMember().then((member: any) => {
      setShouldCollectAdditionalDetails(
        member.identityDocumentType !== IdentityDocumentTypeValue.ID_NUMBER
      );
      existingUserData.phoneNumber = {
        countryCode: state.countryCode,
        globalSubscriberNumber: member.mobileNumber,
      };
      setUserOnboardingDetails(existingUserData);
    });
  }, []);

  const updateSubmitButtonEnabledState = useCallback((value: string) => {
    setIsSubmitButtonEnabled(isValid(value));
  }, []);

  function isValidSexAtBirthInput(sexAtBirth: string) {
    if (sexAtBirth !== "Select") {
      return sexAtBirth;
    }
    return "";
  }

  useEffect(() => {
    if (userOnboardingDetails) {
      const {
        phoneNumber,
        firstName,
        lastName,
        sexAtBirth,
        dateOfBirth,
        marketingChannel,
      } = userOnboardingDetails;
      const isPhoneNumberValid = phoneNumber?.globalSubscriberNumber || "";
      const isMarketingChannelValid = marketingChannel?.name || "";
      const isSexAtBirthValid = isValidSexAtBirthInput(sexAtBirth);

      let isButtonEnabled = isPhoneNumberValid && firstName && lastName;

      if (isLeadSourceEnabled) {
        if (shouldCollectAdditionalDetails) {
          isButtonEnabled =
            isButtonEnabled &&
            isSexAtBirthValid &&
            dateOfBirth &&
            isMarketingChannelValid;
        } else {
          isButtonEnabled = isButtonEnabled && isMarketingChannelValid;
        }
      } else if (shouldCollectAdditionalDetails) {
        isButtonEnabled = isButtonEnabled && isSexAtBirthValid && dateOfBirth;
      }

      updateSubmitButtonEnabledState(isButtonEnabled);
    }
  }, [
    isLeadSourceEnabled,
    shouldCollectAdditionalDetails,
    updateSubmitButtonEnabledState,
    userOnboardingDetails,
  ]);

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    if (userOnboardingDetails) {
      event.preventDefault();
      setIsSubmittingForm(true);
      let formData: SubmitUserDetailsRequest = {
        firstName: userOnboardingDetails.firstName,
        lastName: userOnboardingDetails.lastName,
      };

      if (shouldCollectAdditionalDetails) {
        formData = {
          ...formData,
          dateOfBirth: userOnboardingDetails.dateOfBirth,
          sexAtBirth: userOnboardingDetails.sexAtBirth,
        };
      }

      if (isLeadSourceEnabled && userOnboardingDetails.marketingChannel) {
        let value = null;
        if (
          userOnboardingDetails.marketingChannel.name ===
            MarketingChannelOptions.OTHER &&
          userOnboardingDetails.otherSourceValue
        ) {
          value = userOnboardingDetails.otherSourceValue;
        }

        const transformedMarketingChannel = {
          [userOnboardingDetails.marketingChannel.name]: value,
        };

        formData = {
          ...formData,
          marketingChannels: transformedMarketingChannel,
        };
      }

      submitRetailUserDetails({
        ...formData,
      })
        .then(() => {
          setIsSubmittingForm(false);

          getMember().then((data) => {
            dispatch({
              type: "SET_CURRENT_USER",
              payload: data,
            });
          });
          onDone();
        })
        .catch(() => {
          setIsSubmittingForm(false);
          onError();
        });
    }
  }

  function onDone() {
    if (onCompletedFlow) {
      setIsFormError(false);
      onCompletedFlow();

      trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
        formName: "Member onboarding details",
      });
    }
  }

  function onError() {
    setIsFormError(true);
  }

  return (
    <Stack height="100%" justifyContent="flex-end">
      {isSubmittingForm ? (
        <FullscreenLoadingIndicator />
      ) : (
        <>
          {userOnboardingDetails?.phoneNumber && (
            <form onSubmit={onSubmit}>
              <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 2 }}>
                <Stack spacing={2} sx={{ pb: 5 }}>
                  <FaceIconBrandLarge />
                  <Stack spacing={2}>
                    <Typography variant="h3">
                      {t("CollectRetailUserDetails.title")}
                    </Typography>
                  </Stack>
                </Stack>

                <MemberOnboardingPhoneNumberInput
                  value={userOnboardingDetails.phoneNumber}
                />

                <MemberOnboardingFullNameInput
                  firstNameValue={userOnboardingDetails.firstName}
                  lastNameValue={userOnboardingDetails.lastName}
                  onFirstNameChange={(value: string) =>
                    setUserOnboardingDetails({
                      ...userOnboardingDetails,
                      firstName: value,
                    })
                  }
                  onLastNameChange={(value: string) =>
                    setUserOnboardingDetails({
                      ...userOnboardingDetails,
                      lastName: value,
                    })
                  }
                />

                {isLeadSourceEnabled &&
                  !marketingChannelsFetchError &&
                  !isMarketingChannelsLoading &&
                  marketingChannels &&
                  marketingChannels?.length > 0 && (
                    <MemberOnboardingMarketingChannelInput
                      marketingChannels={marketingChannels}
                      otherSourceValue={userOnboardingDetails.otherSourceValue}
                      marketingChannelValue={
                        userOnboardingDetails.marketingChannel || null
                      }
                      onOtherSourceValueChange={(value: string) =>
                        setUserOnboardingDetails({
                          ...userOnboardingDetails,
                          otherSourceValue: value,
                        })
                      }
                      onSelectChange={(value: MarketingChannel) => {
                        setUserOnboardingDetails({
                          ...userOnboardingDetails,
                          marketingChannel: value,
                          ...(value.name === MarketingChannelOptions.OTHER && {
                            otherSourceValue: "",
                          }),
                        });
                      }}
                    />
                  )}

                {shouldCollectAdditionalDetails && (
                  <>
                    <MemberOnboardingDateOfBirthInput
                      value={userOnboardingDetails.dateOfBirth}
                      onChange={(value: string) =>
                        setUserOnboardingDetails({
                          ...userOnboardingDetails,
                          dateOfBirth: value,
                        })
                      }
                    />

                    <MemberOnboardingSexAtBirthInput
                      value={userOnboardingDetails.sexAtBirth || "Select"}
                      onChange={(value: string) => {
                        setUserOnboardingDetails({
                          ...userOnboardingDetails,
                          sexAtBirth: value,
                        });
                      }}
                    />
                  </>
                )}

                {isFormError && (
                  <FormInputErrorList
                    errors={[t("common.somethingWentWrong")]}
                  />
                )}

                <Button
                  type="submit"
                  color="primary"
                  disabled={!isSubmitButtonEnabled}
                  aria-label={t("common.nextButton")}
                  fullWidth
                >
                  {t("common.nextButton")}
                </Button>
              </Stack>
            </form>
          )}
        </>
      )}
    </Stack>
  );
}
