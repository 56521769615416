import {
  Stack,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import RetailSexAtBirthInfoModal from "../RetailSexAtBirthInfoModal";
import { useTranslation } from "react-i18next";
import { useId, useState } from "react";

interface MemberOnboardingSexAtBirthInputProps {
  value: string;
  onChange: (value: string) => void;
}

export default function MemberOnboardingSexAtBirthInput({
  value,
  onChange,
}: MemberOnboardingSexAtBirthInputProps) {
  const { t } = useTranslation();
  const sexAtBirthInputID = useId();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onSexAtBirthChange(el: any) {
    onChange(el.target.value);
  }

  function onModalOpen() {
    setIsModalOpen(true);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  return (
    <>
      <Stack spacing={1}>
        <InputLabel>
          <Typography color="neutral.700" variant="body2" fontWeight={300}>
            {t("SexAtBirthInput.label")}
          </Typography>
        </InputLabel>
        <Select
          id={sexAtBirthInputID}
          value={value}
          onChange={onSexAtBirthChange}
          inputProps={{
            "aria-label": t("SexAtBirthInput.label"),
          }}
          MenuProps={{
            "aria-label": t("SexAtBirthInput.label"),
          }}
        >
          <MenuItem value={"Select"} disabled>
            <Stack textAlign={"left"}>{t("common.selectPlaceholder")}</Stack>
          </MenuItem>
          <MenuItem value={"Male"}>
            <Stack textAlign={"left"}>{t("common.male")}</Stack>
          </MenuItem>
          <MenuItem value={"Female"}>
            <Stack textAlign={"left"}>{t("common.female")}</Stack>
          </MenuItem>
        </Select>
      </Stack>

      <RetailSexAtBirthInfoModal isOpen={isModalOpen} onClose={onModalClose} />

      <Button
        sx={{ alignSelf: "flex-start" }}
        variant="text"
        onClick={onModalOpen}
        size="small"
      >
        {t("WhyAreWeAskingYouThisModal.title")}
      </Button>
    </>
  );
}
